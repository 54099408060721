<script>
import BaseProfileManager from '../BaseProfileManager'
import { Relation as RelationModel } from '@/models/Connect/Relation'

export default {
  name: 'RelationEdit',

  extends: BaseProfileManager,

  props: {
    relationId: {
      type: String,
      required: true
    }
  },

  computed: {
    isNewModel () {
      return false
    },

    sidebarTitle () {
      return this.$t('pages.relations.edit.sidebar_title')
    },

    sidebarDescription () {
      return this.$t('pages.relations.edit.sidebar_description')
    }
  },

  async mounted () {
    const options = {
      params: {
        brand: this.activeBrandId
      }
    }

    this.isLoading = true

    await this.$api.get(`/connect/relations/${this.relationId}`, options)
      .then((response) => {
        const profile = new RelationModel(response.data.data)

        this.form.merge(profile)

        this.isLoading = false
      })
  },

  methods: {
    onSuccessHandler (response) {
      this.$notify.success({
        title: this.$t('general.success'),
        duration: 5000,
        message: this.$t('success.relation_updated')
      })

      this.form.reset()
      this.$v.form.$reset()

      if (this.$route.query.redirect) {
        return this.$router.push({ path: this.$route.query.redirect })
      }

      if (!this.stayOnPageAfterSave) {
        this.$router.push({ name: 'connect.relation.view', params: { relationId: response.data.id } })
      }
    },

    onErrorHandler (error) {
      this.$displayRequestError(error, this.$t('errors.cannot_update_relation'))
    }
  }
}
</script>
